import { client, navItems } from "../constants/data";

import { Link } from "react-router-dom";
import React from "react";

const Header = () => {
  return (
    <>
      <header className="header-area header-three">
        <div className="header-top second-header d-none d-md-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4 d-none d-lg-block">
                <div className="header-social">
                  <span>
                    Follow us:-
                    {client.socialMedia.map((media, index) => (
                      <a
                        key={index}
                        href={media.url}
                        title={media.name}
                        target={"_blank"}
                      >
                        <i className={media.icon} />
                      </a>
                    ))}
                  </span>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 d-none d-lg-block text-right">
                <div className="header-cta">
                  <ul>
                    <li>
                      <div className="call-box">
                        <div className="icon">
                          <img
                            src="/assets/img/icon/phone-call.png"
                            alt="img"
                          />
                        </div>
                        <div className="text">
                          <span>Call Now !</span>
                          <strong>
                            <a href={`tel:${client.telephone[0]}`}>
                              {client.telephone[0]}
                            </a>
                          </strong>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="call-box">
                        <div className="icon">
                          <img src="/assets/img/icon/mailing.png" alt="img" />
                        </div>
                        <div className="text">
                          <span>Email Now</span>
                          <strong>
                            <a href={`mailto:${client.email[0]}`}>
                              {client.email[0]}
                            </a>
                          </strong>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="header-sticky" className="menu-area">
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3">
                  <Link to="/">
                    <img src="/assets/img/logo/logo.png" alt="logo" />
                  </Link>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="main-menu text-right text-xl-right">
                    <nav id="mobile-menu">
                      <ul>
                        {navItems.map((item, index) => (
                          <li key={index}>
                            <Link to={item.href}>{item.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block text-right text-xl-right">
                  <div className="login">
                    <ul>
                      <li>
                        <div className="second-header-btn">
                          <Link to={"/contact"} className="btn">
                            admission open
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mobile-menu" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
