import { blogs, categories, client } from "../../constants/data";

import { Link } from "react-router-dom";
import React from "react";

const Sidebar = () => {
  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-4">
        <aside className="sidebar-widget">
          <section id="search-3" className="widget widget_search">
            <h2 className="widget-title">Search</h2>
            <form role="search" className="search-form">
              <label>
                <span className="screen-reader-text">Search for:</span>
                <input
                  type="search"
                  className="search-field"
                  placeholder="Search …"
                />
              </label>
              <input type="submit" className="search-submit" value={"Search"} />
            </form>
          </section>
          <section
            id="custom_html-5"
            className="widget_text widget widget_custom_html"
          >
            <h2 className="widget-title">Follow Us</h2>
            <div className="textwidget custom-html-widget">
              <div className="widget-social">
                {client.socialMedia.map((media, index) => (
                  <a href={media.url} key={index}>
                    <i className={media.icon} />
                  </a>
                ))}
              </div>
            </div>
          </section>
          <section id="categories-1" className="widget widget_categories">
            <h2 className="widget-title">Categories</h2>
            <ul>
              {categories.map((category, index) => (
                <li key={index} className="cat-item cat-item-16">
                  <Link to="/">{category.name}</Link> ({category.id * 7})
                </li>
              ))}
            </ul>
          </section>
          <section id="recent-posts-4" className="widget widget_recent_entries">
            <h2 className="widget-title">Recent Posts</h2>
            <ul>
              {blogs &&
                blogs.map((blog, index) => (
                  <li key={index}>
                    <Link to="/">{blog.title}</Link>
                    <span className="post-date">{blog.date}</span>
                  </li>
                ))}
            </ul>
          </section>
          <section id="tag_cloud-1" className="widget widget_tag_cloud">
            <h2 className="widget-title">Tag</h2>
            <div className="tagcloud">
              <a
                href="#"
                className="tag-cloud-link tag-link-28 tag-link-position-1"
                style={{ fontSize: "8pt" }}
                aria-label="app (1 item)"
              >
                app
              </a>
              <a
                href="#"
                className="tag-cloud-link tag-link-17 tag-link-position-2"
                style={{ fontSize: "8pt" }}
                aria-label="Branding (1 item)"
              >
                Branding
              </a>
              <a
                href="#"
                className="tag-cloud-link tag-link-20 tag-link-position-3"
                style={{ fontSize: "8pt" }}
                aria-label="corporat (1 item)"
              >
                corporat
              </a>
              <a
                href="#"
                className="tag-cloud-link tag-link-24 tag-link-position-4"
                style={{ fontSize: "16.4pt" }}
                aria-label="Design (2 items)"
              >
                Design
              </a>
              <a
                href="#"
                className="tag-cloud-link tag-link-25 tag-link-position-5"
                style={{ fontSize: "22pt" }}
                aria-label="gallery (3 items)"
              >
                gallery
              </a>
              <a
                href="#"
                className="tag-cloud-link tag-link-26 tag-link-position-6"
                style={{ fontSize: "8pt" }}
                aria-label="video (1 item)"
              >
                video
              </a>
              <a
                href="#"
                className="tag-cloud-link tag-link-29 tag-link-position-7"
                style={{ fontSize: "16.4pt" }}
                aria-label="web design (2 items)"
              >
                web design
              </a>
            </div>
          </section>
        </aside>
      </div>
    </>
  );
};

export default Sidebar;
