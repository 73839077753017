import { useState, useEffect } from "react";

const StepsArea = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {/* steps-area */}
      <section
        className="steps-area p-relative"
        style={{ backgroundColor: "#032e3f" }}
      >
        <div className="animations-10">
          <img
            src="/assets/img/bg/teacher-with-students.png"
            alt="Teacher With Students"
            style={{
              width: "auto",
              height: "auto",
              transform: "scale(0.5) translate(-70%, -5%)",
              opacity: 0.2,
            }}
          />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div
                className="section-title mb-35 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h2>Our Best Features</h2>
                <p>
                  Education is not just the acquisition of facts and figures,
                  but the awakening of the mind and the heart to the wonders of
                  the world.
                </p>
              </div>
              <ul className="pr-20">
                <li>
                  <div
                    className="step-box wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="dnumber">
                      <div className="date-box">
                        <img src="/assets/img/icon/fea-icon01.png" alt="icon" />
                      </div>
                    </div>
                    <div className="text">
                      <h3>Skilled Teachers</h3>
                      <p>
                        Our institute has skilled teachers who are qualified,
                        experienced, and passionate. They teach, guide, and
                        inspire our students using diverse methods and
                        individualized feedback.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="step-box wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="dnumber">
                      <div className="date-box">
                        <img src="/assets/img/icon/fea-icon02.png" alt="icon" />
                      </div>
                    </div>
                    <div className="text">
                      <h3>Affordable Courses and Programs</h3>
                      <p>
                        Our institute has affordable and accessible courses and
                        programs in various fields and modes. We also have
                        financial aid and flexible payment options for eligible
                        students.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="step-box wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="dnumber">
                      <div className="date-box">
                        <img src="/assets/img/icon/fea-icon03.png" alt="icon" />
                      </div>
                    </div>
                    <div className="text">
                      <h3>Efficient &amp; Flexible</h3>
                      <p>
                        Our institute uses technology and tools to deliver
                        efficient and flexible courses and programs. We also
                        offer different schedules, formats, and options for our
                        students.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="step-img wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img
                  src="/assets/img/bg/steps-img-4.jpg"
                  alt="class image"
                  class={`${windowWidth >= 768 && "frequently-image"}`}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* steps-area-end */}
    </>
  );
};

export default StepsArea;
