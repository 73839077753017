import React from "react";

const MapArea = () => {
  return (
    <>
      {/* map-area-end */}
      <div className="map fix" style={{ background: "#f5f5f5" }}>
        <div className="container-flud">
          <div className="row">
            <div className="col-lg-12">
              <iframe
                src="https://maps.google.com/maps?q=maiwand%20university&t=&z=13&ie=UTF8&iwloc=&output=embed"
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      {/* map-area-end */}
    </>
  );
};

export default MapArea;
