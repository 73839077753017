import axios from "axios";
import React, { useEffect, useState } from "react";

const CoursesArea = () => {
  const [programs, setPrograms] = useState([
    {
      id: 1,
      coverImage: "database.jpg",
      faculty: "BCS",
      title: "Database",
      description:
        "This course will teach you the fundamentals of database design and management. You will....",
    },
    {
      id: 2,
      coverImage: "networking.png",
      faculty: "BCS",
      title: "Networking",
      description:
        "This course will introduce you to the basics of how networks operate and how they are....",
    },
    {
      id: 3,
      coverImage: "finance.jpg",
      faculty: "BBA",
      title: "Finance",
      description:
        "This course will teach you the fundamentals of database design and management. You will....",
    },
    {
      id: 4,
      coverImage: "management.jpg",
      faculty: "BBA",
      title: "Management (HR)",
      description:
        "This course will teach you the fundamentals of database design and management. You will....",
    },
    {
      id: 5,
      coverImage: "no-image.jpg",
      faculty: "Education",
      title: "DLI",
      description:
        "This course will introduce you to the history and development of the Dari language and its....",
    },
    {
      id: 6,
      coverImage: "no-image.jpg",
      faculty: "Education",
      title: "IED",
      description:
        "This course will introduce you to the history and development of Islamic education and its role....",
    },
    {
      id: 7,
      coverImage: "no-image.jpg",
      faculty: "Education",
      title: "HIS",
      description:
        "The history department in our institute offers a comprehensive and interdisciplinary....",
    },
    {
      id: 8,
      coverImage: "no-image.jpg",
      faculty: "Education",
      title: "MTH",
      description:
        "The math department in your institute offers a rigorous and diverse curriculum that....",
    },
    {
      id: 9,
      coverImage: "dit.jpg",
      faculty: "IT",
      title: "CIT Course",
      description:
        "This course will teach you the fundamentals of database design and management. You will....",
    },
    {
      id: 10,
      coverImage: "dit.jpg",
      faculty: "IT",
      title: "DIT Course",
      description:
        "This course will teach you the fundamentals of database design and management. You will....",
    },
    {
      id: 11,
      coverImage: "cel.jpg",
      faculty: "English",
      title: "CEL Course",
      description:
        "This course will teach you the fundamentals of database design and management. You will....",
    },
    {
      id: 12,
      coverImage: "del.jpg",
      faculty: "English",
      title: "DEL Course",
      description:
        "This course will teach you the fundamentals of database design and management. You will....",
    },
  ]);

  useEffect(() => {
    window.coursesAreaSlider();
  }, []);

  return (
    <>
      {/* courses-area */}
      <section className="courses pt-120 pb-120 p-relative fix">
        <div className="animations-01">
          <img src="/assets/img/bg/an-img-03.png" alt="an-img-01" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 p-relative">
              <div
                className="section-title center-align mb-50 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>
                  <i className="fal fa-graduation-cap" /> Our Courses
                </h5>
                <h2>Graduate Programs</h2>
              </div>
            </div>
          </div>
          <div className="row class-active">
            {programs &&
              programs.map((program) => (
                <div className="col-lg-4 col-md-6">
                  <div className="courses-item mb-30 hover-zoomin">
                    <div className="thumb fix">
                      <a href="/assets/single-courses.html">
                        <img
                          src={`/assets/img/programs/${program.coverImage}`}
                          alt={program.faculty}
                        />
                      </a>
                    </div>
                    <div className="courses-content">
                      <div className="cat">
                        <i className="fal fa-graduation-cap" />{" "}
                        {program.faculty}
                      </div>
                      <h3>
                        <a href="/assets/single-courses.html">
                          {program.title}
                        </a>
                      </h3>
                      <p>{program.description}</p>
                      <a
                        href="/assets/single-courses.html"
                        className="readmore"
                      >
                        Read More <i className="fal fa-long-arrow-right" />
                      </a>
                    </div>
                    <div className="icon">
                      <img src="/assets/img/icon/cou-icon.png" alt="img" />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      {/* courses-area */}
    </>
  );
};

export default CoursesArea;
