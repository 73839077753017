export const client = {
  name: "Maiwand Institute Of Higher Education",
  email: ["info@maiwand.edu.af", "support@maiwand.edu.af"],
  address: "4th Street Taimani Project Square, Kabul, Afghanistan",
  telephone: ["+93 78 690 5007", "+93 76 690 5007"],
  about: `The Institute of Maiwand was established in 2007 to provide high-quality and affordable
          computer science and business education that prepares students for the challenges and opportunities
          of the digital economy and society. It provides degree programs, online courses, workshops and seminars
          that aim to enhance the knowledge, skills and employability of its students. It is located in Kabul and
          has 50 staff and partners with local and international organizations and universities.`,
  socialMedia: [
    {
      name: "Facebook",
      icon: "fab fa-facebook-f",
      url: "https://facebook.com/maiwanduniversity",
    },
    {
      name: "Instagram",
      icon: "fab fa-instagram",
      url: "https://instagram.com/maiwanduniversity",
    },
    {
      name: "Whatsapp",
      icon: "fab fa-whatsapp",
      url: "https://wa.me/+93786905007",
    },
    {
      name: "Telegram",
      icon: "fab fa-telegram",
      url: "https://web.telegram.org/",
    },
    {
      name: "YouTube",
      icon: "fab fa-youtube",
      url: "https://youtube.com/maiwanduniversity",
    },
    {
      name: "Twitter",
      icon: "fab fa-twitter",
      url: "https://twitter.com",
    },
  ],
};

export const navItems = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Blog",
    href: "/blog",
  },
  {
    label: "Contact",
    href: "/contact",
  },
];

export const categories = [
  { id: 1, name: "Graduations" },
  { id: 2, name: "Branding" },
  { id: 3, name: "Discounts" },
  { id: 4, name: "News" },
  { id: 5, name: "Gallery" },
];

export const blogs = [
  {
    id: 1,
    image: "8th-Graduated.jpg",
    views: 387,
    comments: 244,
    title: "Maiwand Students’ Twenty-first Graduation",
    description: `The sun bathed the Maiwand Institute’s courtyard in warm hues as students, clad in gowns, gathered for their long-awaited graduation. Each face held a unique story—a tale of resilience, friendship, and growth.
                  The principal’s words echoed the spirit of Maiwand: “Education shapes character, fosters empathy, and ignites curiosity.” Awards were bestowed upon the highest achievers—the scholars who had pushed boundaries, the artists who had painted their dreams, and the athletes who had sprinted toward victory.
                  As the sun dipped below the horizon, the graduates tossed their caps into the air, laughter and cheers filling the air. They were no longer just students; they were torchbearers, carrying the legacy of Maiwand into uncharted territories.
                  And so, Maiwand Institute’s Eighth Graduation came to an end, leaving behind memories etched in hearts. The spirit of learning lingered—a flame that would guide these graduates toward their destinies.`,
    date: "10th August 2023",
  },
  {
    id: 2,
    image: "1402-entrance-image.jpg",
    views: 837,
    comments: 12,
    title: "One day opportunity should not be missed!!!",
    description: `Stepping into a new phase of life is akin to opening a fresh chapter in a well-worn book. The pages are crisp, the ink still wet, and the possibilities endless. Excitement dances with uncertainty, and you find
                  yourself at the crossroads of familiarity and uncharted territory. Remember this: You have worked tirelessly, stitching together moments, dreams, and aspirations. The late nights spent poring over textbooks, the camaraderie
                  shared in crowded classrooms, the quiet victories when you conquered your doubts—all of it has led you here. You stand on the threshold, diploma in hand, ready to leap into the unknown. Challenges await, like hidden riddles
                  waiting to be unraveled. But fear not. Each challenge is a stepping stone, a chance to grow stronger, wiser. The canvas of your future awaits your brushstrokes—the colors vibrant, waiting for your touch. Whether you’re pursuing
                  further studies, embarking on a career, or exploring passions, remember that this is your time. Celebrate the journey, for it’s not just about the destination. Cherish the friendships forged, the mentors who shaped you, and the
                  quiet moments of introspection. The world beckons, and you are its eager traveler. Pack your courage, curiosity, and resilience—they will serve you well. So go forth, with hope as your compass and determination as your guide.
                  The road may twist, but you’ll find your way. And when you look back, you’ll see how far you’ve come—a constellation of memories illuminating your path. Welcome to this new chapter, where the ink is yours to spill, the margins
                  yours to annotate. May it be a tale of courage, kindness, and unwavering belief—a story uniquely yours.`,
    date: "17th August 2023",
  },
  {
    id: 3,
    image: "1402-special-discount-programs.jpg",
    comments: 54,
    title: "New Student Special Discount at Maiwand Institute",
    description: `If you’re eager to embark on a new educational journey, look no further! Maiwand Institute of Higher Education has unveiled a special opportunity for aspiring learners. Whether you’re a recent graduate, a career changer,
                  or simply curious, our institute invites you to step into the world of knowledge and growth. Our experienced faculty members are passionate mentors, ready to guide you toward success. Whether you’re fascinated by technology, language,
                  or business, Maiwand Institute has a program tailored for you.`,
    date: "26th July 2023",
  },
];
