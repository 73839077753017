import BrandArea from "../../components/BrandArea";
import BreadCrumbArea from "../../layouts/BreadCrumbArea";
import ContactArea from "./ContactArea";
import MapArea from "./MapArea";
import React from "react";
import ServiceArea from "./ServiceArea";
import useTitle from "../../hooks/useTitle";

// components

const Contact = () => {
  useTitle("Contact");
  return (
    <main>
      <BreadCrumbArea title={"Contact Us"} subTitle={"Contact"} />
      <ServiceArea />
      <MapArea />
      <ContactArea />
      {/* <BrandArea /> */}
    </main>
  );
};

export default Contact;
