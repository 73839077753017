import BreadCrumbArea from "../../layouts/BreadCrumbArea";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { blogs } from "../../constants/data";
import useTitle from "../../hooks/useTitle";

const Blog = () => {
  useTitle("Blog");

  return (
    <>
      <main>
        <BreadCrumbArea title={"Our Blog"} subTitle={"Blog"} />
        <section className="inner-blog pt-120 pb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                {blogs &&
                  blogs.map((blog) => (
                    <div className="bsingle__post mb-50">
                      <div className="bsingle__post-thumb">
                        <img
                          src={`/assets/img/blog/${blog.image}`}
                          alt="cover-image"
                        />
                      </div>
                      <div className="bsingle__content">
                        <div className="meta-info">
                          <div className="meta-info">
                            <ul>
                              <li>
                                <i className="fal fa-eye" /> {blog.views} Views
                              </li>
                              <li>
                                <i className="fal fa-comments" />{" "}
                                {blog.comments} Comments
                              </li>
                              <li>
                                <i className="fal fa-calendar-alt" />{" "}
                                {blog.date}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <h2>
                          <Link href="/">{blog.title}</Link>
                        </h2>
                        <p>{blog.description}</p>
                        <div className="blog__btn">
                          <Link href="/" className="btn">
                            Read More <i className="fal fa-long-arrow-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <Sidebar />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Blog;
