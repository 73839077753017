import React from "react";

const AdmissionArea = () => {
  return (
    <>
      {/* admission-area */}
      <section
        className="about-area about-p pt-120 pb-120 p-relative fix"
        style={{
          backgroundImage: "url(/assets/img/bg/admission_bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img
                  src="/assets/img/admission-aid.jpg"
                  alt="img"
                  style={{ borderRadius: 30 }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content pl-15 wow fadeInRight animated"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h2>Admission &amp; Aid</h2>
                </div>
                <p className="txt-clr">
                  At our institute, we are committed to providing quality
                  education to students from diverse backgrounds and
                  circumstances. We have a holistic and transparent admission
                  process that evaluates each applicant based on their academic
                  achievements, personal qualities, and potential contributions
                  to our community. We also offer a variety of financial aid
                  options to help students afford their education, such as
                  scholarships, grants, loans, and work-study programs. Our
                  admission and aid counselors are ready to assist you with any
                  questions or concerns you may have about applying to and
                  enrolling in our institute.
                </p>
                <div className="slider-btn mt-20">
                  <a
                    href="/assets/about.html"
                    className="btn ss-btn smoth-scroll"
                  >
                    Read More <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* admission-area-end */}
    </>
  );
};

export default AdmissionArea;
