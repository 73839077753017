import React from "react";

const AboutArea = () => {
  return (
    <>
      {/* about-area */}
      <section className="about-area about-p pt-120 pb-120 p-relative fix">
        <div className="animations-02">
          <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src="/assets/img/features/about_img_07.png" alt="img" />
                <div className="about-text second-about three-about">
                  <span>
                    16 <sub>+</sub>
                  </span>
                  <p>Years of Experience</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content pl-15 wow fadeInRight animated"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>
                    <i className="fal fa-graduation-cap" /> About Our Institute
                  </h5>
                  <h2>A Few Words About the Institute</h2>
                </div>
                <p className="txt-clr">
                  We are optimistic about the future and we want you to be part
                  of it. That’s why we are inviting you to join our institute
                  and explore new possibilities and solutions with us. Our
                  institute is different because we use the approach of design
                  thinking and combine it with the knowledge of various
                  disciplines. This way, we can offer you a learning experience
                  that is tailored to your interests and goals, and that can
                  empower you to make a positive impact on your life and the
                  world.
                </p>
                <p>
                  We are a leading educational institution that was established
                  in 2007 with a vision to provide quality education and skills
                  development for our students. We have a team of 90 dedicated
                  staff members who are committed to delivering excellence in
                  teaching and learning. We offer a range of programs in various
                  disciplines, such as Business and Computer Science, that are
                  designed to equip our graduates with the knowledge and
                  competencies they need to succeed in the global market.
                  Whether you are looking for a bachelor’s degree, a diploma, or
                  a short course, we have something for you. Join us today and
                  discover the opportunities that await you at our institute!
                </p>
                {/* <div className="about-content2">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="green2">
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>01</span>
                            </div>
                            <div className="text">
                              <h3>Doctoral Degrees</h3>
                              <p>
                                consectetur adipiscing elit sed do eiusmod tem
                                incid idunt.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>02</span>
                            </div>
                            <div className="text">
                              <h3>Global Students</h3>
                              <p>
                                consectetur adipiscing elit sed do eiusmod tem
                                incid idunt.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-area-end */}
    </>
  );
};

export default AboutArea;
