import { useState, useEffect } from "react";

const FrequentlyArea = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {/* frequently-area */}
      <section className="faq-area pt-120 pb-120 p-relative fix">
        <div className="animations-10">
          <img
            src="/assets/img/bg/maiwand-apartment.png"
            alt="Maiwand Apartment"
            style={{
              width: "auto",
              height: "auto",
              transform: "scale(0.5) translate(-45%, 100%)",
              opacity: 0.1,
            }}
          />
        </div>
        <div className="animations-08">
          <img
            src="/assets/img/bg/customer-service.png"
            alt="Students Service"
            style={{
              width: "450px",
              height: "auto",
              transform: "scale(0.5) translate(60%, -30%)",
              opacity: 0.1,
            }}
          />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7">
              <div
                className="section-title wow fadeInLeft animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
              >
                <h2>Get every single answer here.</h2>
                <p>
                  Our institute is not just a place of learning, but a place of
                  excellence. We strive to provide our students with the best
                  education, the best teachers, and the best opportunities for
                  their future.
                </p>
              </div>
              <div
                className="faq-wrap mt-30 pr-30 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button
                          className="faq-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                        >
                          What are the rules and regulations of your institute?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body">
                        We have some rules and regulations that you need to
                        follow as a student of our institute. These rules and
                        regulations are:
                        <ul>
                          <li>
                            You need to attend at least 80% of your classes and
                            complete all your assignments and exams on time
                          </li>
                          <li>
                            You need to respect the rights, dignity, and
                            diversity of all members of our institute community
                          </li>
                          <li>
                            You need to abide by the code of conduct and ethics
                            of our institute and avoid any academic dishonesty
                            or misconduct
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="faq-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          How can I apply for financial aid or scholarships at
                          your institute?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body">
                        We offer financial aid and scholarships to eligible
                        students who need support to pursue their education at
                        our institute. To apply for financial aid or
                        scholarships, you need to:
                        <ol>
                          <li>
                            Fill out the financial aid or scholarship
                            application form on our website or at our admission
                            office
                          </li>
                          <li>
                            Submit the required documents, such as your tazkira,
                            transcripts, income statement, and letter of
                            recommendation
                          </li>
                          <li>
                            Meet the academic and financial criteria for each
                            type of financial aid or scholarship
                          </li>
                          <li>
                            Wait for the decision of our financial aid or
                            scholarship committee
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="faq-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                        >
                          When and where can I take the entrance exam?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body">
                        The entrance exam dates and locations are announced on
                        our website and social media platforms. You can also
                        contact our admission office to find out more. The
                        entrance exam is usually held in multiple sessions.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h2 className="mb-0">
                        <button
                          className="faq-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                        >
                          What are the programs and courses that you offer?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFour"
                      className="collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body">
                        We offer a variety of programs and courses in different
                        fields and disciplines, such as languages, arts,
                        sciences, business, and technology. You can find the
                        list of our programs and courses on our website or
                        contact our academic office for more information.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0">
                        <button
                          className="faq-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                        >
                          What are the tuition fees and payment options for your
                          institute?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFive"
                      className="collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body">
                        The tuition fees vary depending on the program and the
                        number of credits you take. You can find the fee
                        schedule on our website or contact our finance office
                        for more information. We offer different payment
                        options, such as:
                        <ol>
                          <li>
                            Paying in full at the beginning of each semester
                          </li>
                          <li>
                            Paying in installments throughout the semester
                          </li>
                          <li>Applying for financial aid or scholarships</li>
                          <li>Using a bank loan or a sponsor</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="contact-bg02">
                <div
                  className="section-title wow fadeInDown animated"
                  data-animation="fadeInDown"
                  data-delay=".4s"
                >
                  <h2>Make An Contact</h2>
                  <span
                    className={`text-white ${
                      windowWidth <= 768 && "text-center"
                    }`}
                    style={{ display: "block" }}
                  >
                    We invite you to share your comments and questions with us!
                  </span>
                </div>
                <form
                  action="http://htmldemo.zcubethemes.com/qeducato/mail.php"
                  method="post"
                  className="contact-form mt-30 wow fadeInUp animated"
                  data-animation="fadeInUp"
                  data-delay=".4s"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="contact-field p-relative c-name mb-20">
                        <input
                          type="text"
                          id="firstn"
                          name="firstn"
                          placeholder="First Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-field p-relative c-subject mb-20">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-field p-relative c-subject mb-20">
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder="Phone No."
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-field p-relative c-message mb-30">
                        <textarea
                          name="message"
                          id="message"
                          cols={30}
                          rows={10}
                          placeholder="Write comments"
                          defaultValue={""}
                        />
                      </div>
                      <div className="slider-btn">
                        <button
                          className="btn ss-btn"
                          data-animation="fadeInRight"
                          data-delay=".8s"
                        >
                          <span>Submit Now</span>
                          <i className="fal fa-long-arrow-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* frequently-area-end */}
    </>
  );
};

export default FrequentlyArea;
