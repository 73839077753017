import React from "react";

const StepsArea = () => {
  return (
    <>
      <div>
        {/* steps-area */}
        <section
          className="steps-area2 p-relative fix"
          style={{ backgroundColor: "#125875" }}
        >
          <div className="animations-02">
            <img src="/assets/img/bg/an-img-10.png" alt="an-img-01" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div
                  className="step-box step-box2 wow fadeInUp animated"
                  data-animation="fadeInUp"
                  data-delay=".4s"
                >
                  <div className="dnumber">
                    <div className="date-box">
                      <img src="/assets/img/icon/fea-icon01.png" alt="icon" />
                    </div>
                  </div>
                  <div className="text">
                    <h2>Our Mission</h2>
                    <p>
                      Our mission at Maiwand Institute is to provide quality
                      education that fosters personal and social growth. We
                      offer various programs such as BCS, BBA, and others that
                      prepare our students with the knowledge, skills, and
                      values they need to excel in their professions and
                      contribute to society. Since 2007, we have expanded to
                      more than 90 staff members who are committed to
                      innovation, excellence, and diversity in teaching,
                      research, and service. We celebrate our accomplishments
                      and our vision for the future.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div
                  className="step-img2 wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                >
                  <img
                    src="/assets/img/bg/steps-img-4.jpeg"
                    alt="class image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* steps-area-end */}
        {/* steps-area */}
        <section
          className="steps-area2 p-relative fix"
          style={{ backgroundColor: "#032e3f" }}
        >
          <div className="container">
            <div className="animations-08">
              <img src="/assets/img/bg/an-img-20.png" alt="contact-bg-an-01" />
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div
                  className="step-img3 wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                >
                  <img src="/assets/img/bg/steps-img-5.jpg" alt="class image" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div
                  className="step-box step-box3 wow fadeInUp animated"
                  data-animation="fadeInUp"
                  data-delay=".4s"
                >
                  <div className="dnumber">
                    <div className="date-box">
                      <img src="/assets/img/icon/fea-icon03.png" alt="icon" />
                    </div>
                  </div>
                  <div className="text">
                    <h2>Powerful Alumni</h2>
                    <p>
                      Since 2007, our institute has been nurturing graduates who
                      excel in their fields. Our alumni have achieved remarkable
                      success in various sectors, such as business, computer
                      science, education, and more. Among our distinguished
                      alumni are leaders, innovators, and experts who work for
                      prestigious organizations, cutting-edge companies, and
                      influential institutions. We are honored by our alumni
                      community and the contributions they have made in their
                      respective domains.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* steps-area-end */}
      </div>
    </>
  );
};

export default StepsArea;
