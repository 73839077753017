import AboutArea from "./AboutArea";
import BrandArea from "../../components/BrandArea";
import BreadCrumbArea from "../../layouts/BreadCrumbArea";
import CtaArea from "../../components/CtaArea";
import FrequentlyArea from "../../components/FrequentlyArea";
import React from "react";
import StepsArea from "./StepsArea";
import TestimonialArea from "../../components/TestimonialArea";
import useTitle from "../../hooks/useTitle";

// components

const About = () => {
  useTitle("About");

  return (
    <main>
      <BreadCrumbArea title={"About Us"} subTitle={"About"} />
      <AboutArea />
      <CtaArea />
      <FrequentlyArea />
      <StepsArea />
      <TestimonialArea />
      {/* <BrandArea /> */}
    </main>
  );
};

export default About;
