import React from "react";

const AboutArea = () => {
  return (
    <>
      {/* about-area */}
      <section
        className="about-area about-p pt-120 pb-120 p-relative fix"
        style={{ background: "#eff7ff" }}
      >
        <div className="animations-02">
          <img
            src="/assets/img/bg/maiwand-apartment.png"
            alt="Maiwand Apartment"
            style={{
              width: "auto",
              height: "auto",
              transform: "scale(0.5) translate(-20%, 70%)",
              opacity: 0.2,
            }}
          />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img
                  src="/assets/img/features/about_img_07.png"
                  width={700}
                  alt="img"
                />
                <div className="about-text second-about">
                  <span>
                    16 <sub>+</sub>
                  </span>
                  <p>Years of Experience</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content pl-15 wow fadeInRight animated"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>
                    <i className="fal fa-graduation-cap" /> About Our Institute
                  </h5>
                  <h2>A Few Words About the Institute</h2>
                </div>
                <p className="txt-clr">
                  We believe that the future can be better than the present.
                  That’s why we are inviting our community to join us in
                  creating new possibilities and solutions. Our institute is
                  unique because we combine the power of design thinking with
                  the expertise of various disciplines. This way, we can offer
                  our students a learning experience that is relevant to their
                  interests and goals, and that can transform their lives and
                  the world.
                </p>
                <p>
                  The Institute of Maiwand was established in 2007 to provide
                  high-quality and affordable computer science and business
                  education that prepares students for the challenges and
                  opportunities of the digital economy and society. It provides
                  degree programs, online courses, workshops and seminars that
                  aim to enhance the knowledge, skills and employability of its
                  students. It is located in Kabul and has 50 staff and partners
                  with local and international organizations and universities.
                </p>
                {/* <div className="about-content2">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="green2">
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>01</span>
                            </div>
                            <div className="text">
                              <h3>Bachelor Degrees</h3>
                              <p>
                                Computer science, bachelor of business
                                administration, education
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>02</span>
                            </div>
                            <div className="text">
                              <h3>Courses</h3>
                              <p>
                                Our institute offers courses in [subjects] that
                                teach you design thinking skills and [other
                                skills] that you can apply to your personal and
                                professional projects.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                <div className="slider-btn mt-20">
                  <a
                    href="/assets/about.html"
                    className="btn ss-btn smoth-scroll"
                  >
                    Read More <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-area-end */}
    </>
  );
};

export default AboutArea;
