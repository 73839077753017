import React, { useState, useEffect } from "react";

const BlogArea = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs([
      {
        id: 1,
        coverImage: "8th-Graduated.jpg",
        views: 394,
        title: "Maiwand Students’ Twenty-first Graduation",
        description:
          "Maiwand Students’ Eighth Graduation was a great success! The graduation included students....",
        date: "10th August 2023",
      },
      {
        id: 2,
        coverImage: "1402-entrance-image.jpg",
        views: 469,
        title: "One day opportunity should not be missed!!!",
        description:
          "Entering a new phase of life can be both exciting and challenging. Remember that you have worked...",
        date: "17th August 2023",
      },
      {
        id: 3,
        coverImage: "1402-special-discount-programs.jpg",
        views: 839,
        title: "New Student Special Discount at Maiwand Institute",
        description:
          "Are you looking to start a new course? Look no further! Maiwand Institute is offering a special....",
        date: "26th July 2023",
      },
    ]);
  }, []);

  return (
    <>
      {/* blog-area */}
      <section
        id="blog"
        className="blog-area p-relative fix pt-120 pb-90"
        // style={{
        //   backgroundImage: "url(/assets/img/bg/blog-background-image.jpg)",
        //   backgroundRepeat: "no-repeat",
        //   backgroundPosition: "top",
        // }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div
                className="section-title center-align mb-50 text-center wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>
                  <i className="fal fa-graduation-cap" /> Our Blog
                </h5>
                <h2>Latest Blog &amp; News</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {blogs &&
              blogs.map((blog) => (
                <div className="col-lg-4 col-md-6">
                  <div
                    className="single-post2 hover-zoomin mb-30 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="blog-thumb2">
                      <a href="#">
                        <img
                          src={`/assets/img/blog/${blog.coverImage}`}
                          alt={blog.title}
                          style={{ height: 282 }}
                        />
                      </a>
                      <div className="date-home">
                        <i className="fal fa-calendar-alt" /> {blog.date}
                      </div>
                    </div>
                    <div className="blog-content2">
                      <div className="b-meta">
                        <div className="meta-info">
                          <ul>
                            <li>
                              <i className="fal fa-user" /> By Admin
                            </li>
                            <li>
                              <i className="fal fa-eye" /> {blog.views} Views
                            </li>
                          </ul>
                        </div>
                      </div>
                      <h4>
                        <a href="#">{blog.title}</a>
                      </h4>
                      <p>{blog.description}</p>
                      <div className="blog-btn">
                        <a href="#">
                          Read More <i className="fal fa-long-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      {/* blog-area-end */}
    </>
  );
};

export default BlogArea;
