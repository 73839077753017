import { useEffect } from "react";

export default function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title + " - Maiwand Institute Of Higher Education";
    return () => {
      document.title = prevTitle;
    };
  });
}
