import React from "react";

// components
import SliderArea from "./SliderArea";
import ServiceArea from "./ServiceArea";
import AboutArea from "./AboutArea";
import CoursesArea from "./CoursesArea";
import StepsArea from "./StepsArea";
import EventArea from "./EventArea";
import CtaArea from "../../components/CtaArea";
import FrequentlyArea from "../../components/FrequentlyArea";
import VideoArea from "./VideoArea";
import TestimonialArea from "../../components/TestimonialArea";
import SearchArea from "./SearchArea";
import AdmissionArea from "./AdmissionArea";
import BrandArea from "./BrandArea";
import BlogArea from "./BlogArea";
import NewsLetterArea from "./NewsLetterArea";

// hooks
import useTitle from "../../hooks/useTitle";

const Home = () => {
  useTitle("Home");

  return (
    <>
      {/* main-area */}
      <main>
        <SliderArea />
        <ServiceArea />
        <AboutArea />
        <CoursesArea />
        <StepsArea />
        <BlogArea />
        {/* <EventArea /> */}
        <CtaArea />
        <FrequentlyArea />
        <VideoArea />
        <TestimonialArea />
        {/* <SearchArea /> */}
        {/* <BrandArea /> */}
        <NewsLetterArea />
        <AdmissionArea />
      </main>
      {/* main-area-end */}
    </>
  );
};

export default Home;
