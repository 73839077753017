import { client, navItems } from "../constants/data";

import { Link } from "react-router-dom";
import React from "react";

const Footer = () => {
  return (
    <>
      {/* footer */}
      <footer
        className="footer-bg footer-p pt-90"
        style={{
          backgroundColor: "#125875",
          backgroundImage: "url(/assets/img/bg/footer-bg.png)",
        }}
      >
        <div className="footer-top pb-70">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>About Us</h2>
                  </div>
                  <div className="f-contact">
                    <p>
                      With over 16 years of experience, our institute is a
                      trusted name in BBC, BBA, and other programs that prepare
                      students for the challenges and opportunities of the
                      future. Our institute was founded in 2007 with a vision of
                      creating a learning environment that fosters creativity
                      and collaboration.
                    </p>
                  </div>
                  <div className="footer-social mt-10">
                    {client.socialMedia.map((media, index) => (
                      <a
                        key={index}
                        href={media.url}
                        title={media.name}
                        target={"_blank"}
                      >
                        <i className={media.icon} />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Our Links</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      {navItems.map((item, index) => (
                        <li key={index}>
                          <Link to={item.href}>{item.label}</Link>
                        </li>
                      ))}
                      <li>
                        <a href="https://maiwand.edu.af:2096">Web-mail Login</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Latest Post</h2>
                  </div>
                  <div className="recent-blog-footer">
                    <ul>
                      <li>
                        <div className="thum">
                          <img
                            src="/assets/img/blog/8th-Graduated.jpg"
                            alt="img"
                            style={{
                              height: "80px",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </div>
                        <div className="text">
                          <a href="#">
                            Maiwand Students’ Twenty-first Graduation
                          </a>
                          <span>10 August, 2023</span>
                        </div>
                      </li>
                      <li>
                        <div className="thum">
                          <img
                            src="/assets/img/blog/7th.jpg"
                            alt="img"
                            style={{
                              height: "80px",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </div>
                        <div className="text">
                          <a href="#">
                            Maiwand Students’ Eighteenth Graduation
                          </a>
                          <span>05 September, 2022</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Contact Us</h2>
                  </div>
                  <div className="f-contact">
                    <ul>
                      <li>
                        <i className="icon fal fa-phone" />
                        <span>
                          {client.telephone.map((phone, index) => (
                            <div key={index}>
                              <a href={`tel:${phone}`}>{phone}</a>{" "}
                              {index == 0 && <br />}
                            </div>
                          ))}
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-envelope" />
                        <span>
                          {client.email.map((mail, index) => (
                            <div key={index}>
                              <a href={`mailto:${mail}`}>{mail}</a>
                              {index == 0 && <br />}
                            </div>
                          ))}
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-map-marker-check" />
                        <span>{client.address}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <Link to="/">
                  <h5 className={"text-white"}>{client.name}</h5>
                </Link>
              </div>
              <div className="col-lg-8 text-center text-xl-right">
                Copyright © {client.name} {new Date().getFullYear()} . All
                rights reserved. Powered By <strong>Rawan Technologies</strong>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer-end */}
    </>
  );
};

export default Footer;
