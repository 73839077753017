import React from "react";
import { Link } from "react-router-dom";

const BreadCrumbArea = ({ title, subTitle }) => {
  return (
    <>
      {/* breadcrumb-area */}
      <section
        className="breadcrumb-area d-flex p-relative align-items-center"
        style={{ backgroundImage: "url(/assets/img/bg/bdrc-bg_02.png)" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  <h2>{title}</h2>
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {subTitle}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* breadcrumb-area-end */}
    </>
  );
};

export default BreadCrumbArea;
