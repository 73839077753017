import React from "react";

const CtaArea = () => {
  return (
    <>
      {/* cta-area */}
      <section
        className="cta-area cta-bg pt-50 pb-50"
        style={{ backgroundImage: "url(/assets/img/bg/cta_bg_03.jpg)" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div
                className="section-title cta-title wow fadeInLeft animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
              >
                <h2>Scholarship Programs</h2>
                <p>
                  At our institute, we believe that education is a right, not a
                  privilege. That’s why we offer generous scholarships and
                  financial aid to our students who demonstrate academic
                  excellence, leadership potential, and financial need. With our
                  support, you can achieve your dreams without worrying about
                  the cost.
                </p>
              </div>
            </div>
            <div className="col-lg-4 text-right">
              <div
                className="cta-btn s-cta-btn wow fadeInRight animated mt-30"
                data-animation="fadeInDown animated"
                data-delay=".2s"
              >
                <a href="#" className="btn ss-btn smoth-scroll">
                  Financial Aid <i className="fal fa-long-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* cta-area-end */}
    </>
  );
};

export default CtaArea;
