import React, { useEffect, useRef, useState } from "react";

const ServiceArea = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [services, setServices] = useState([
    {
      id: 1,
      title: "B.C.S",
      description:
        "B.C.S faculty are the mentors of tomorrow’s leaders. They inspire, challenge and empower their students.",
      img: "bcs.png",
    },
    {
      id: 2,
      title: "B.B.A",
      description:
        "B.B.A faculty are the guides of future entrepreneurs. They teach, support and motivate their students.",
      img: "bba.png",
    },
    {
      id: 3,
      title: "E.D.U",
      description:
        "Education is the key to unlock the doors of opportunity. It is the light that illuminates the path of knowledge.",
      img: "edu.png",
    },
  ]);

  return (
    <>
      {/* service-area */}
      <section className="service-details-two p-relative">
        <div className="container">
          <div className="row">
            {services &&
              services.map((service) => (
                <div className="col-lg-4 col-md-12 col-sm-12" key={service.id}>
                  <div
                    className={`services-box07 ${
                      service.id == 2 && "active"
                    } top-corner-rounded  ${
                      windowWidth <= 768 && "bottom-corner-rounded mb-4"
                    }`}
                  >
                    <div className="sr-contner">
                      <div className="icon">
                        <img
                          src={`/assets/img/faculties/${service.img}`}
                          alt="icon01"
                        />
                      </div>
                      <div className="text">
                        <h5>
                          <a href="#">{service.title}</a>
                        </h5>
                        <p>{service.description}</p>
                        <a href="#">
                          Read More <i className="fal fa-long-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      {/* service-details2-area-end */}
    </>
  );
};

export default ServiceArea;
