import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const SliderArea = () => {
  useEffect(() => {
    window.mainSlider();
  }, []);

  return (
    <>
      {/* slider-area */}
      <section id="home" className="slider-area fix p-relative">
        <div className="slider-active" style={{ background: "#141b22" }}>
          <div
            className="single-slider slider-bg"
            style={{
              backgroundImage: "url(/assets/img/slider/slider_bg_04.jpg)",
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="slider-content s-slider-content mt-130">
                    <h5 data-animation="fadeInUp" data-delay=".4s">
                      welcome To M.I.H.E
                    </h5>
                    <h2 data-animation="fadeInUp" data-delay=".4s">
                      Education is not a destination; education is a journey. 🚀
                    </h2>
                    <p data-animation="fadeInUp" data-delay=".6s">
                      We are proud of our 21st batch of BBA and BCS graduates.
                      They have learned a lot and shown their skills in business
                      and computer science. They are ready for their future, and
                      we thank their teachers, mentors, family and friends.
                      Congrats to them on this achievement!🎓👏👍🎉
                    </p>
                    <div className="slider-btn mt-30">
                      <a
                        href="/assets/about.html"
                        className="btn ss-btn mr-15"
                        data-animation="fadeInLeft"
                        data-delay=".4s"
                      >
                        Discover More <i className="fal fa-long-arrow-right" />
                      </a>
                      <Link
                        to="/contact"
                        className="btn ss-btn active"
                        data-animation="fadeInLeft"
                        data-delay=".4s"
                      >
                        Contact Us <i className="fal fa-long-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 p-relative" />
              </div>
            </div>
          </div>
          <div
            className="single-slider slider-bg"
            style={{
              backgroundImage: "url(/assets/img/slider/slider_bg_05.jpg)",
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="slider-content s-slider-content mt-130">
                    <h5 data-animation="fadeInUp" data-delay=".4s">
                      welcome To M.I.H.E
                    </h5>
                    <h2 data-animation="fadeInUp" data-delay=".4s">
                      Education is not a burden; education is a privilege. 🎓
                    </h2>
                    <p data-animation="fadeInUp" data-delay=".6s">
                      Join us for high-quality education and training in various
                      fields and programs. Our professional teachers are experts
                      who provide personalized and interactive learning. We have
                      flexible and affordable options, and a great service that
                      meets your needs. Achieve your academic and professional
                      goals with us. 🎓🌟👩‍🏫👨‍🎓👏
                    </p>
                    <div className="slider-btn mt-30">
                      <a
                        href="/assets/about.html"
                        className="btn ss-btn mr-15"
                        data-animation="fadeInLeft"
                        data-delay=".4s"
                      >
                        Discover More <i className="fal fa-long-arrow-right" />
                      </a>
                      <Link
                        to="/contact"
                        className="btn ss-btn active"
                        data-animation="fadeInLeft"
                        data-delay=".4s"
                      >
                        Contact Us <i className="fal fa-long-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 p-relative" />
              </div>
            </div>
          </div>
          <div
            className="single-slider slider-bg"
            style={{
              backgroundImage: "url(/assets/img/slider/slider_bg_02.jpg)",
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="slider-content s-slider-content mt-130">
                    <h5 data-animation="fadeInUp" data-delay=".4s">
                      welcome To M.I.H.E
                    </h5>
                    <h2 data-animation="fadeInUp" data-delay=".4s">
                      Education is the most powerful weapon which you can use to
                      change the world.
                    </h2>
                    <p data-animation="fadeInUp" data-delay=".6s">
                      Education is the process of acquiring knowledge, skills,
                      values, and attitudes that enable individuals to grow and
                      contribute to society. It can take place in formal or
                      informal settings, and it can be lifelong or limited to a
                      specific period.
                    </p>
                    <div className="slider-btn mt-30">
                      <a
                        href="/assets/about.html"
                        className="btn ss-btn mr-15"
                        data-animation="fadeInLeft"
                        data-delay=".4s"
                      >
                        Discover More <i className="fal fa-long-arrow-right" />
                      </a>
                      <Link
                        to="/contact"
                        className="btn ss-btn active"
                        data-animation="fadeInLeft"
                        data-delay=".4s"
                      >
                        Contact Us <i className="fal fa-long-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 p-relative" />
              </div>
            </div>
          </div>
          <div
            className="single-slider slider-bg"
            style={{
              backgroundImage: "url(/assets/img/slider/slider_bg_03.jpg)",
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="slider-content s-slider-content mt-130">
                    <h5 data-animation="fadeInUp" data-delay=".4s">
                      welcome To M.I.H.E
                    </h5>
                    <h2 data-animation="fadeInUp" data-delay=".4s">
                      Education is not preparation for life; education is life
                      itself.
                    </h2>
                    <p data-animation="fadeInUp" data-delay=".6s">
                      Education is the key to unlocking the potential of every
                      human being. It empowers people to think critically,
                      communicate effectively, and solve problems creatively. It
                      also fosters respect, tolerance, and compassion for
                      others.
                    </p>
                    <div className="slider-btn mt-30">
                      <a
                        href="/assets/about.html"
                        className="btn ss-btn mr-15"
                        data-animation="fadeInLeft"
                        data-delay=".4s"
                      >
                        Discover More <i className="fal fa-long-arrow-right" />
                      </a>
                      <Link
                        to="/contact"
                        className="btn ss-btn active"
                        data-animation="fadeInLeft"
                        data-delay=".4s"
                      >
                        Contact Us <i className="fal fa-long-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 p-relative" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* slider-area-end */}
    </>
  );
};

export default SliderArea;
