import React, { useEffect, useState } from "react";

const TestimonialArea = () => {
  const [data, setData] = useState([
    {
      name: "Sayed Ajmal",
      image: "/assets/img/testimonial/MIHE12012-0077.jpg",
      position: "B.C.S - Student",
      message:
        "I had a positive and rewarding experience at your institute. The courses were informative and stimulating, and the instructors were passionate and inspiring. The feedback was helpful and encouraging, and the resources were plentiful and relevant. I developed a deeper understanding and appreciation of the subject matter.",
    },
    {
      name: "Mohammad Tamim",
      image: "/assets/img/testimonial/MIHE12012-0100.jpg",
      position: "B.C.S - Student",
      message:
        "Your institute has been a great place to learn and grow. The curriculum was well-designed and challenging, and the feedback was constructive and timely. I appreciate the support and guidance I received from the staff and mentors. I would recommend your institute to anyone who wants to advance their career.",
    },
    {
      name: "Mohammad Haroon",
      image: "/assets/img/testimonial/MIHE12012-0107.jpg",
      position: "B.C.S - Student",
      message:
        "I had a wonderful experience at your institute. The courses were interactive and fun, and the assignments were practical and useful. The instructors were friendly and approachable, and the peers were supportive and collaborative. I gained valuable insights and skills that will help me in my future endeavors.",
    },
    {
      name: "Khoshal Naser",
      image: "/assets/img/testimonial/MIHE12014-0120.jpg",
      position: "B.B.A - Student",
      message:
        "I am very satisfied with the quality of education provided by your institute. The courses were comprehensive and up-to-date, and the instructors were experienced and professional. The online platform was easy to use and accessible. I feel confident and prepared for the next step in my career.",
    },
    {
      name: "Sayed Noman",
      image: "/assets/img/testimonial/MIHE12014-0297.jpg",
      position: "B.C.S - Student",
      message:
        "Your institute has exceeded my expectations. The courses were flexible and affordable, and the instructors were supportive and responsive. The content was rich and diverse, and the assessments were fair and meaningful. I acquired new knowledge and skills that will benefit me in my work and life.",
    },
  ]);

  useEffect(() => {
    window.testimonialSlider();
  }, []);

  return (
    <>
      {/* testimonial-area */}
      <section className="testimonial-area pt-120 pb-115 p-relative fix">
        <div className="animations-01">
          <img
            src="/assets/img/bg/student-feedbacks.png"
            alt="Student Feedbacks"
            style={{
              width: "600px",
              height: "auto",
              transform: "scale(0.5) translate(-55%, 35%)",
              opacity: 0.1,
            }}
          />
        </div>
        <div className="animations-02">
          <img
            src="/assets/img/bg/maiwand-apartment.png"
            alt="Maiwand Apartment"
            style={{
              width: "auto",
              height: "auto",
              transform: "scale(0.5) translate(50%, 50%)",
              opacity: 0.1,
            }}
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center mb-50 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>
                  <i className="fal fa-graduation-cap" /> Testimonial
                </h5>
                <h3>How Our Students Achieved Their Goals</h3>
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="testimonial-active wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                {data.map((feedback) => (
                  <div className="single-testimonial text-center">
                    <div className="qt-img">
                      <img
                        src="/assets/img/testimonial/qt-icon.png"
                        alt="img"
                      />
                    </div>
                    <p>{feedback.message}</p>
                    <div className="testi-author">
                      <img
                        src={feedback.image}
                        alt={feedback.name}
                        style={{
                          width: "70px",
                          height: "71px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div className="ta-info">
                      <h6>{feedback.name}</h6>
                      <span>{feedback.position}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonial-area-end */}
    </>
  );
};

export default TestimonialArea;
