import React from "react";

const VideoArea = () => {
  return (
    <>
      {/* video-area */}
      <section
        className="cta-area cta-bg pt-160 pb-160"
        style={{
          backgroundImage:
            "url(/assets/img/bg/about-section-background-image.jpg)",
        }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div
                className="section-title cta-title video-title wow fadeInLeft animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
              >
                <h2>
                  We're <span>M.I.H.E</span> &amp; We're Diffirent
                </h2>
                <p>
                  Our institute stands out from other institutes in many ways.
                  We offer a variety of programs and courses that are
                  affordable, accessible, and relevant to the needs and
                  interests of our students. We have skilled and experienced
                  teachers who are passionate about their subjects and who care
                  about their students’ success. We have modern facilities and
                  resources that provide our students with the best learning
                  environment and opportunities. We have a supportive and
                  inclusive community that fosters respect, diversity,
                  collaboration, and innovation. We have a high graduation rate
                  and a strong reputation in the market and the society. Our
                  institute is not just a place of learning, but a place of
                  excellence.
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-2" />
            <div className="col-lg-4">
              <div className="s-video-content">
                <a
                  href="/assets/https://www.youtube.com/watch?v=gyGsPlt06bo"
                  className="popup-video mb-50"
                >
                  <img
                    src="/assets/img/bg/play-button.png"
                    alt="circle_right"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* video-area-end */}
    </>
  );
};

export default VideoArea;
